
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useVideos, useDeleteVideo, usePublishVideo } from '@/composables/api';
import { VideoStatus } from '@/interfaces/Video';
import {
  VideoIdOption,
  IntBoolean,
  triggerExportVideosCsv
} from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Draft',
        value: VideoStatus.DRAFT
      },
      {
        label: 'Auditing',
        value: VideoStatus.AUDITING
      },
      {
        label: 'Scheduling',
        value: VideoStatus.SCHEDULING
      },
      {
        label: 'Put file Pending',
        value: VideoStatus.PUTFILE_PENDING
      },
      {
        label: 'Put File Processing',
        value: VideoStatus.PUTFILE_PROCESSING
      },
      {
        label: 'Put File Failed',
        value: VideoStatus.PUTFILE_FAILED
      },
      {
        label: 'Processing',
        value: VideoStatus.PROCESSING
      },
      {
        label: 'Published',
        value: VideoStatus.PUBLISHED
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: 'Is Free',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Yes',
        value: '1'
      },
      {
        label: 'No',
        value: '0'
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const status = ref();
    const isFree = ref();
    const route = useRoute();
    const sortBy = ref();
    const descSortBy = ref('serial_number');
    const { t } = useI18n();

    const { data, isLoading, isFetching, refetch } = useVideos({ page, keyword, status, isFree, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteVideo();
    const { isLoading: isPublishLoading, mutate: publish } = usePublishVideo();

    const statusIndex = ref(undefined);

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];

      isFree.value = event[1];
    };

    const deleteVideo = ({ videoId }: VideoIdOption, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        'Are you sure you want to delete?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ videoId }, {
            onSuccess() {
              refetch.value();

              ElMessage({
                type: 'success',
                message: 'Delete successfully'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    const publishVideo = ({ videoId }: VideoIdOption) => {
      ElMessageBox.confirm(
        'Are you sure you want to publish?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          publish({ videoId }, {
            onSuccess() {
              refetch.value();

              ElMessage({
                type: 'success',
                message: 'Publish successfully'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    const exportCsv = async() => {
      const exportSuccessful = await triggerExportVideosCsv();

      if (exportSuccessful) {
        // Handle success (e.g., show a success message)
        console.log('export csv success', exportSuccessful);
      } else {
        // Handle failure (e.g., show an error message)
        console.log('export csv fail', exportSuccessful);
      }
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      data,
      page,
      isLoading,
      IntBoolean,
      isFetching,
      VideoStatus,
      statusIndex,
      FILTER_OPTIONS,
      isDeletedLoading,
      isPublishLoading,
      sortChange,
      deleteVideo,
      publishVideo,
      searchKeyword,
      t,
      exportCsv,
      handleFilterChange
    };
  }
});
